body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.none {
  height: 0;
  width: 0;
  opacity: 0;
}

.file__wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
  transition: .3s ;
  cursor: pointer;
  padding: 16px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 24px;
}

.file__wrapper:hover > .file__name{
  color: #40a9ff;
  transition: .3s;
}

.file__name{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #bfbfbf;
  transition: .3s;
  max-width: 107px;
  max-height: 66px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file__wrapper:hover{
  border-color: #40a9ff;
  transition: .3s;
}

.file__wrapper:hover::after{
  color: #40a9ff;
}

.file__wrapper::after{
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-filename);
  color: #bfbfbf;
  transition: .3s;
}

.file__delete{
  position: absolute;
  right: -15px;
  top: -17px;
  cursor: pointer;
}

.uploadfile__wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
  transition: .3s ;
  cursor: pointer;
  padding: 16px;
  margin-right: 24px;
}

.uploadfile__wrapper:hover{
  border-color: #40a9ff;
  transition: .3s;
}

.success_blink{
  transition: .3s;
  background-color: lightgreen;
}

.error_blink{
  transition: .3s;
  background-color: lightcoral;
}

.notification__inline-create{
  width: 100%;
  padding: 16px;
  height: 100%;
  opacity: .3;
  transition: .3s;
  border: 1px solid rgb(240 240 240)
}

.notification__inline-create:hover{
  opacity: 1;
  transition: .3s;
}

.logo{
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0
}